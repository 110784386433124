<template>
    <section id="lighting-jobs" class="lib-paddings">
        <Text1 class="text-center"> Lighting <span class="lib-color-orange">Projects</span> </Text1>
        <div class="flex justify-center">
            <div class="projects lib-hide-scroll relative" ref="projects">
                <template v-for="item in projectsComputed">
                    <div class="project" :class="item.addedClass" :style="`background-image:url(${require(`@/assets/projects/${item.image}`)})`">
                        <a href="javascript:void(0)" @click="setFullScreen(item.id)" class="h-full w-full">
                            <!-- <img :src="" :alt="item.name" /> -->
                        </a>
                    </div>
                </template>
                <ArrowRight @onClick="move(1)" class="desktop" />
                <ArrowLeft @onClick="move(-1)" class="desktop" />
            </div>
        </div>
        <ArrowContainer class="lib-mobile">
            <ArrowLeft arrowType="mobile"  class="mobile-project-arrow-left"  @onClick="move(-1)" />
            <ArrowRight arrowType="mobile"  class="mobile-project-arrow-right" @onClick="move(1)" />
        </ArrowContainer>
    </section>
</template>
<script>
import Text1 from '@/components/Text1';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
import ArrowContainer from '@/components/ArrowContainer';
export default {
    components: { Text1, ArrowLeft, ArrowRight, ArrowContainer},
    data() {
        return {
            page: 1,
            slide: '',
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.page = 1;
        });
    },

    computed: {
       
        screenWidth() {
            return this.$store.state.screenWidth;
        },

        projects() {
            return this.$store.state.projects
        },
        perpage() {
            let perpage = 1;
            let screenwidth = this.screenWidth;
            if (screenwidth >= 1280) {
                perpage = 6;
            } else if (screenwidth >= 808) {
                perpage = 2;
            } else if (screenwidth >= 640) {
                perpage = 1;
            }
            return perpage;
        },

        projectsComputed() {
            let screenwidth = this.screenWidth;

            const startAt = (this.page - 1) * this.perpage;
            let cloned = JSON.parse(JSON.stringify(this.projects));
            let cloned1 = JSON.parse(JSON.stringify(this.projects));
            const filtered = cloned.slice(startAt, startAt + this.perpage);

            const projects = cloned1.map((project) => {
                if (!filtered.find((item) => item.name === project.name)) {
                    project.addedClass = 'hide-element';
                } else {
                    project.addedClass = this.slide;
                }
                return project;
            });

            return projects;
        },
    },

    methods: {
        move(v) {
            if (v === 1) {
                this.slide = 'lib-slide-in';
            } else {
                this.slide = 'lib-slide-out';
            }

            let page = this.page;
            const maxPage = Math.ceil(this.projects.length / this.perpage);
            page = this.page + v;

            if (page < 1) {
                this.page = maxPage;
            } else if (page > maxPage) {
                this.page = 1;
            } else {
                this.page = page;
            }
        },
         setFullScreen(img) {
            this.$store.commit('setFullScreen', img)
        },
    },
};
</script>

<style scoped lang="scss">
section {
    background: #0f0d3f;
    overflow: hidden;

    .projects {
        padding-top: 53.54px;
        display: flex;
        align-items: center;
        width: auto;
        column-gap: 20px;
        row-gap: 20px;
        position: relative;
        padding-left: 30px;
        padding-right: 30px;
        scroll-snap-type: x mandatory;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;

        .project {
            position: relative;
            display: flex;
            width: 100%;
            max-width: 300px;
            height: 200px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            align-items: center;
            overflow: hidden;
            scroll-snap-align: center;
            box-shadow: 0px 2.72172px 19.7324px rgba(0, 0, 0, 0.25);
        }
    }

    .hide-element {
        display: none !important;
    }
}

@media (min-width: 390px) {
    section {
        .projects {
            .project {
            }
        }
    }
}

@media (min-width: 808px) {
    section {
        .projects {
            position: relative;
            display: flex;
            justify-content: center;
            overflow: visible;
            flex-wrap: wrap;
            padding-right: 0;
            padding-left: 0;
            margin: 7.5px;
            width: 1102px;
            .project {
                height: 280px;
                width: 280px;
                position: relative !important;
            }
        }
        :deep(.projects) {
            .arrow {
                display: none;
            }
        }
    }
}

@media (min-width: 1280px) {
    section {
        .projects {
            position: relative;
            display: flex;
            justify-content: center;
            overflow: visible;
            flex-wrap: wrap;
            padding-right: 0;
            padding-left: 0;
            margin: 7.5px;
            display: grid;
            grid-template-columns: auto auto auto auto;

            .project {
                height: 280px;
                width: 280px;
                display: flex;
                min-width: auto !important;
                position: relative !important;
                align-items: center;
            }
        }
        :deep(.projects) {
            .arrow {
                display: none;
            }
        }
    }
}
</style>
