<template>
    <Main>
        <SectionMain />
        <OurProducts />
        <LightingProjects />
        <Testimonials />
        <ContactUs />
    </Main>
</template>

<script>
import Main from '@/components/Main';
import SectionMain from './SectionMain';
import ContactUs from './ContactUs';
import OurProducts from './OurProducts';
import OurBrands from './OurBrands';
import LightingProjects from './LightingProjects';
import Testimonials from './Testimonials';

export default {
    components: {
        Main,
        SectionMain,
        ContactUs,
        OurProducts,
        OurBrands,
        LightingProjects,
        Testimonials,
    },
};
</script>
<style scoped lang="scss"></style>
