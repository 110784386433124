<template>
    <section id="testimonials" class="lib-paddings" :class="[showMore && 'showMore']">
        <div class="blur1 blur"></div>

        <Text1 class="text-center">
            <span class="lib-color-orange">Testimonials</span>
        </Text1>

        <div class="flex container-testimonials">
            <div class="testimonials">
                <template v-for="item in testimonialsComputed">
                    <div class="testimonial" :class="item.addedClass">
                        <div class="content">
                            <div class="brand">
                                <span :style="'background:' + item.bgcolor">
                                    <img :src="require(`@/assets/testimonials/${item.image}`)" :alt="item.title" sizes="(min-width: 576px) 540px, 100vw"
                                /></span>
                            </div>
                            <div class="container-text">
                                <h5>
                                    <span class="lib-color-orange" v-text="item.title"></span>
                                </h5>
                                <p v-text="item.description"></p>
                            </div>
                        </div>
                    </div>
                </template>
                <ArrowLeft arrowType="desktop" @onClick="move(-1)" />
                <ArrowRight arrowType="desktop" @onClick="move(1)" />
            </div>
        </div>

        <ArrowContainer class="lib-mobile">
            <ArrowLeft arrowType="mobile" class="mobile-testimonials-arrow-left" @onClick="move(-1)" />
            <ArrowRight arrowType="mobile" class="mobile-testimonials-arrow-right"  @onClick="move(1)" />
        </ArrowContainer>
    </section>
</template>

<script>
import Text1 from '@/components/Text1';
import ArrowLeft from '@/components/ArrowLeft';
import ArrowRight from '@/components/ArrowRight';
import ArrowContainer from '@/components/ArrowContainer';

export default {
    components: {Text1,ArrowRight, ArrowLeft, ArrowContainer},

    data() {
        return {
            testimonials: [
                {
                    id: 1,
                      description: ' Top notch customer service. Quality products at a very reasonable cost. You can’t go wrong with Elm lighting. Highly recommend!',
                    title: 'Joseph & Sons',
                    image: 'joseph-and-sons.jpeg',
                    bgcolor: '#202020;',
                },
                {
                    id: 2,
                    description:
                        'We’ve been working with ELM for many years. Their customer service is impressive and their products are of the highest quality. They are our first stop for electrical supplies.',
                    title: 'Method Electric',
                    image: 'method-electric.png',
                    bgcolor: '#fff',
                },
                {
                    id: 3,
                    description:
                        'Our business has grown a lot over the years, and Elm Lighting was an important part of it. They carry everything a contractor needs to install high end lighting systems. And they guide you every step of the way.',
                    title: 'Firefly',
                    image: 'firefly.png',
                    bgcolor: 'rgb(254, 163, 12);',
                },
                {
                    id: 4,
                
                      description: `I work as a contractor, and I deal with ELM on a regular basis for all my electrical needs. I have always been impressed by their technical knowledge and dedication. I highly recommend them to anyone looking for an electrical supplier they can rely on.`,
                    title: 'Remo Consultants',
                    image: 'remo-consultants-llc.png',
                    bgcolor: '#fff;',
                },
            ],
            page: 1,
            slide: '',
            perpage: 1,
            showMore: false,
        };
    },
    computed: {
        screenWidth() {
            return this.$store.state.screenWidth;
        },

        testimonialsComputed() {
            let screenwidth = this.screenWidth;
            this.perpage = 1;

            if (screenwidth >= 1280) {
                this.perpage = 4;
            } else if (screenwidth >= 768) {
                this.perpage = 2;
            } else if (screenwidth >= 640) {
                this.perpage = 1;
            }

            const startAt = (this.page - 1) * this.perpage;
            let cloned = JSON.parse(JSON.stringify(this.testimonials));
            let cloned1 = JSON.parse(JSON.stringify(this.testimonials));
            const filtered = cloned.slice(startAt, startAt + this.perpage);

            const testimonials = cloned1.map((testimonial) => {
                if (!filtered.find((item) => item.id === testimonial.id)) {
                    testimonial.addedClass = 'hide-element';
                } else {
                    testimonial.addedClass = this.slide;
                }
                return testimonial;
            });

            return testimonials;
        },
    },

    methods: {
        toggleProducts() {
            if (this.screenWidth >= 1280) {
                this.$nextTick(() => {
                    if (!this.showMore) {
                        const element = document.getElementById('testimonials');
                        element.scrollIntoView({behavior: 'instant'});
                    }
                });
            }
            this.showMore = !this.showMore;
        },

        move(v) {
            if (v === 1) {
                this.slide = 'lib-slide-in';
            } else {
                this.slide = 'lib-slide-out';
            }

            let page = this.page;
            const maxPage = Math.ceil(this.testimonials.length / this.perpage);
            page = this.page + v;

            if (page < 1) {
                this.page = maxPage;
            } else if (page > maxPage) {
                this.page = 1;
            } else {
                this.page = page;
            }
        },
    },
};
</script>

<style scoped lang="scss">
section {
    position: relative;
    overflow: hidden;
    .container-testimonials {
        justify-content: flex-start;
    }
    .container-button {
        padding-top: 41.6px;
    }
    .testimonials {
        flex-wrap: wrap;
        display: flex;
        padding-left: 21.26px;
        scroll-snap-type: x mandatory;
        padding-right: 21.26px;
        width: 100%;
        justify-content: center;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
        .testimonial {
            margin: calc(15px / 2);
            position: relative;
            border-top: calc(33vw / 2) solid transparent;
            scroll-snap-align: center;
                text-align: center;

            .content {
                background: #ffffff;
                box-shadow: 0px 5px 15px rgba(134, 134, 134, 0.09);
                position: relative !important;
                margin-right: 0 !important;
                margin-left: 0 !important;
                border-radius: 7.37421px;
                padding-top: calc(33vw / 2 + 12px);
                width: 100%;
                user-select: none;
                height: 100%;
                min-height: 210px;
                padding-bottom: 32px;
                border-bottom: 7.8px solid #0f0d3f;
            }
            h5 {
                font-size: 14px;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                line-height: 164.5%;
            }

            .brand {
                width: 33vw;
                height: 33vw;
                border: rgba(245, 118, 33, 0.16) 4.3px solid;
                padding: 3.9px;
                border-radius: 50%;
                position: absolute;
                overflow: hidden;
                background: white;
                right: 0px;
                top: calc(33vw / -2);
                left: 0px;
                margin-right: auto;
                margin-left: auto;
                span {
                    width: 100%;
                    display: flex;
                    border-radius: 50%;
                    height: 100%;
                    align-items: center;
                    overflow: hidden;
                }
            }
            .container-text {
                padding-right: 20.39px;
                padding-left: 20.39px;
                display: flex;
                flex-direction: column;
                p {
                    text-align: center;
                    padding-top: 15.58px;
                    font-size: 3.8vw;
                    line-height: 164.5%;
                    font-family: Poppins;
                    color: #0f0d3f;
                    max-width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .hide-element {
            display: none !important;
        }
    }

    .blur1 {
        position: absolute;
        width: 30vw;
        height: 30vw;
        left: 3vw;
        bottom: 4vw;
        background: rgba(225, 111, 31, 0.39);
        opacity: 0.39;
        mix-blend-mode: multiply;
        filter: blur(177.5px);
        transform: rotate(-135deg);
    }
}

@media (min-width: 320px) {
    section {
        .container-testimonials {
            justify-content: center;
        }
        .testimonials {
            padding-left: 26.52px;
            padding-right: 26.52px;
            .testimonial {
                margin: calc((26.52px / 2) + 0px);
                border-top: calc(95.25px / 2) solid transparent;
                h5 {
                    font-size: 17.3188px;
                }
                .brand {
                    width: 95.25px;
                    height: 95.25px;
                    border: rgba(245, 118, 33, 0.16) 5.41px solid;
                    padding: 4.88px;
                    top: calc(95.25px / -2);
                }

                .content {
                    box-shadow: 0px 2.70607px 8.1182px rgba(134, 134, 134, 0.09);
                    border-radius: 9.20063px;
                    padding-top: calc(95.25px / 2 + 12px);
                    padding-bottom: 38.97px;
                    border-bottom: 9.7px solid #0f0d3f;
                    width: 251.65px;
                }

                .container-text {
                    padding-right: 20.39px;
                    padding-left: 20.39px;
                    display: flex;
                    flex-direction: column;
                    p {
                        padding-top: 15.58px;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    @media (min-width: 640px) {
        section {
            .testimonials {
                padding-top: 17px;
                padding-left: 43.79px;
                padding-right: 43.79px;
                .testimonial {
                    margin: calc(30px / 2);
                    border-top: calc(157px / 2) solid transparent;
                    h5 {
                        font-size: 25px;
                    }
                    .brand {
                        width: 157px;
                        height: 157px;
                        border: rgba(245, 118, 33, 0.16) 9px solid;
                        padding: 8.05px;
                        top: calc(157px / -2);
                    }

                    .content {
                        padding-top: 75.51px;
                        border-radius: 15.19px;
                        box-shadow: 0px 4.46765px 13.4029px rgba(134, 134, 134, 0.09);
                        width: 301.12px;
                        min-height: 353px;
                        border-bottom: 16px solid #0f0d3f;
                        padding-top: calc(157px / 2 + 12px);
                    }

                    .container-text {
                        padding-right: 20px;
                        padding-left: 20px;
                        display: flex;
                        flex-direction: column;
                        p {
                            padding-top: 20px;
                            font-size: 12.5094px;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) {
}

@media (min-width: 1025px) {
    .testimonials {
        flex-direction: row;
    }
}

@media (min-width: 1536px) {
}
@media (min-width: 1280px) {
    section {

        .blur1 {
            position: absolute;
            width: 450.54px;
            height: 435px;
            left: 50px;
            bottom: 2vw;
        }

        .container-button {
            display: flex;
        }
        .container-button {
            display: none !important;
        }
        .testimonials {
            // width: 60%;
          
            .testimonial {
              margin: calc(20px / 2);
                .content {
                width: 290px;
            }
            }
            padding-right: 10px;
            padding-left: 10px;
            display: grid;
            grid-template-columns: auto auto auto auto;
        }
    }
}
@media (min-width: 1366px) {
    section {
        .testimonials {
            .testimonial {
                margin: calc(26px / 2);
                .content {
                                      width: 301.12px;

            }
            }
        }
    }
}
@media (min-width: 1440px) {
    section {
        .testimonials {
            width: 100%;
            .testimonial {
                margin: calc(30px / 2);
                .content {
                }
            }
        }
    }
}

section.showMore {
    .testimonials {
        .testimonial,
        .hide-element {
            display: flex !important;
        }
    }
    .arrow-container {
        display: none;
    }
}
</style>
